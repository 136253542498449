import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as routes from 'redux/modules/Routes/constants';
import { LandingPage } from 'redux/modules/LandingPage/container';
import RemotePage from 'app/redux/modules/RemotePage/container';
import RemoteJoinPage from 'pages/RemoteJoin';
import RemoteSupportPage from 'pages/RemoteSupport';
import RemoteWithCustomerPage from 'pages/RemoteWithCustomer';
import ApplicationPresentationPage from 'pages/ApplicationPresentation';
import Mobile from 'pages/Mobile';
import YourDetails from 'pages/YourDetails';
import EnergySelection from 'redux/modules/Energy/Selection/container';
import OrderSummary from 'pages/OrderSummary';
import ThankYou from 'redux/modules/ThankYouPageNew/switch';
import Payment from 'pages/Payment';
import FingerprintsWithChallenge from 'pages/FingerprintsWithChallenge';
import EnergyDetails from 'redux/modules/Energy/Details/container';
import Broadband from 'pages/Broadband';
import CashbackCardUpsell from 'redux/modules/Cashback/container';
import BundlesPage from 'components/pages/BundlesPage';
import {
  PartnerProxyLogin,
  PartnerSSO,
} from 'redux/modules/PartnerLogin/container';
import { SalesLogin } from 'app/redux/modules/SalesLogin/containers/william.js';
import { SalesLoginIAM } from 'app/redux/modules/SalesLogin/containers/iam.js';
import { SalesLoginTTJWT } from 'app/redux/modules/SalesLogin/containers/tt-jwt.js';
import BoilerCover from 'pages/BoilerCover';
import BillIncomeProtector from 'pages/BillIncomeProtector';
import NotFound from 'pages/NotFound';
import VerificationEnd from 'pages/VerificationEnd';
import Loading from 'pages/Loading';
import PreselectLoading from 'pages/Preselect';
import Restore from 'redux/modules/Restore/container';
import RestoreLanding from 'redux/modules/RestoreLanding/container';
import EntryOrganic from 'redux/modules/EntryOrganic/container';
import Insurance from 'components/pages/Insurance';
import AddOns from 'components/pages/AddOns';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/organic" component={EntryOrganic} />
    <Route exact path="/remotely" component={RemoteJoinPage} />
    <Route exact path="/remotely/start" component={RemotePage} />
    <Route
      exact
      path="/remotely/start/supported"
      component={RemoteWithCustomerPage}
    />
    <Route exact path="/remotely/support" component={RemoteSupportPage} />
    <Route exact path="/restore" component={RestoreLanding} />
    <Route exact path="/restore/start" component={Restore} />
    <Route exact path="/presentation" component={ApplicationPresentationPage} />
    <Route exact path="/home" component={LandingPage} />
    <Route exact path="/bundles" component={BundlesPage} />
    <Route exact path="/energy/selection" component={EnergySelection} />
    <Route exact path="/energy/summary" component={EnergyDetails} />
    <Route exact path="/broadband" component={Broadband} />
    <Route exact path="/mobile" component={Mobile} />
    <Route exact path="/boilerhomecover" component={BoilerCover} />
    <Route exact path="/billincomeprotector" component={BillIncomeProtector} />
    <Route exact path="/cashbackcard" component={CashbackCardUpsell} />
    <Route exact path="/order/summary" component={OrderSummary} />
    <Route exact path={routes.YourDetails} component={YourDetails} />
    <Route exact path="/checkout/payment" component={Payment} />
    <Route exact path="/checkout/thankyou" component={ThankYou} />
    <Route
      exact
      path="/checkout/verification"
      component={FingerprintsWithChallenge}
    />
    <Route exact path="/checkout/verificationEnd" component={VerificationEnd} />
    <Route exact path="/sign-in" component={PartnerSSO} />
    <Route exact path="/proxy-sign-in" component={PartnerProxyLogin} />
    <Route exact path="/sign-in/sales" component={SalesLogin} />
    <Route exact path="/sign-in/sales-iam" component={SalesLoginIAM} />
    <Route exact path="/sign-in/tt" component={SalesLoginTTJWT} />
    <Route exact path="/loading" component={Loading} />
    <Route exact path="/preselect" component={PreselectLoading} />
    <Route path="/insurance" component={Insurance} />
    <Route path="/addons" component={AddOns} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
